import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import { enumFromArray } from './array-to-enum';

export const AccessRequestStatus = Object.freeze({
  DENIED: 'DENIED',
  EXPIRED: 'EXPIRED',
  GRANTED: 'GRANTED',
  PENDING: 'PENDING',
});

/**
 * Get a human readable label for the corresponding `AccessRequestStatus` member.
 * @param {string} type
 * @returns {string} access request status label
 */
export function getAccessRequestStatusLabel(type) {
  if (!AccessRequestStatus[type]) {
    throw new TypeError(`Unknown AccessRequestStatus type \`${type}\``);
  }

  switch (type) {
    case AccessRequestStatus.DENIED:
      return 'Geweigerd';
    case AccessRequestStatus.EXPIRED:
      return 'Verlopen';
    case AccessRequestStatus.GRANTED:
      return 'Toegang verleend';
    case AccessRequestStatus.PENDING:
      return 'In afwachting';
  }
}

export const accessRequestStatusMixin = Object.freeze({
  mixins: [nonReactiveMembersMixin(() => ({ AccessRequestStatus }))],
  methods: { getAccessRequestStatusLabel },
});
