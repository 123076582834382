import { accessRequestStatusMixin } from '@/lib/access-request-status';
import gql from 'graphql-tag';
import GetOrganisationById from '@/graphql/queries/GetOrganisationById.gql';
import { GraphQL } from '@/lib/graphql';
import { vmConfirm } from '@/lib/vm-confirm';

const ACCESS_REQUEST_FRAGMENT = `
  id
  createdAt
  status
  grantedAt
  grantedUntil
  schoolId
  school {
    id
    name
  }
  requesterId
  requester {
    id
    fullName
  }
`;

/**
 * Access management mixin
 */
export const accessManagerMixin = {
  mixins: [accessRequestStatusMixin],

  computed: {
    schoolId() {
      if (this.$route.params.resourceType === 'school') {
        return this.$route.params.resourceId;
      }

      return this.$route.params.organisationId;
    },

    accessRequests() {
      return this._accessRequests ?? [];
    },

    pendingAccessRequests() {
      if (this.$user.role === 'ADMIN') return 0;
      return (
        this._accessRequests?.filter(
          (request) => request.status === this.AccessRequestStatus.PENDING,
        ) ?? 0
      );
    },

    pendingAccessRequestsAmount() {
      return this.pendingAccessRequests.length;
    },
  },

  created() {
    validate.call(this);
  },

  methods: {
    async createAccessRequest() {
      const doContinue = await vmConfirm.call(
        this,
        'Toegang aanvragen',
        `Wil je toegang aanvragen bij ${this.school.name}`,
      );

      if (!doContinue) return;

      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation CreateAccessRequest($input: CreateAccessRequestInput!) {
              createAccessRequest(input: $input) {
                ${ACCESS_REQUEST_FRAGMENT}
              }
            }
          `,

          variables: { input: { schoolId: this.schoolId } },
        });

        this.$Message.success('Toegang aangevraagd!');
        this.$apollo.queries._accessRequests.refetch();
      } catch (error) {
        this.$Modal.error({
          title: 'Er ging iets mis...',
          content: 'Probeer het later nog eens',
        });
      }
    },

    async cancelAccessRequest(id) {
      const doContinue = await vmConfirm.call(
        this,
        'Toegangsverzoek annuleren',
        'Weet je het zeker?',
      );

      if (!doContinue) return;

      try {
        const { data } = await this.$apollo.mutate({
          mutation: gql`mutation DeleteAccessRequest {
            deleteAccessRequest(id: "${id}")
          }`,
        });

        if (data?.deleteAccessRequest) {
          this.$apollo.queries._accessRequests.refetch();
        }
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },

    async denyAccessRequest(id) {
      const doContinue = await vmConfirm.call(
        this,
        'Toegangsverzoek weigeren',
        'Weet je zeker dat je toegang wil weigeren?',
      );

      if (!doContinue) return;

      try {
        const { data } = await this.$apollo.mutate({
          mutation: gql`mutation UpdateAccessRequest($input: UpdateAccessRequestInput!) {
            updateAccessRequest(input: $input) {
              ${ACCESS_REQUEST_FRAGMENT}
            }
          }`,

          variables: {
            input: { id, data: { status: 'DENIED' } },
          },
        });

        if (data) {
          this.$apollo.queries._accessRequests.refetch();
        }
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },

    async acceptAccessRequest(id) {
      const today = new Date();
      const grantedUntil = new Date();
      // grant access for 72 hours
      grantedUntil.setDate(today.getDate() + 3);

      const doContinue = await vmConfirm.call(
        this,
        'Toegangsverzoek accepteren',
        'Weet je zeker dat je toegang wil verlenen?',
      );

      if (!doContinue) return;

      try {
        const { data } = await this.$apollo.mutate({
          mutation: gql`mutation UpdateAccessRequest($input: UpdateAccessRequestInput!) {
            updateAccessRequest(input: $input) {
              ${ACCESS_REQUEST_FRAGMENT}
            }
          }`,

          variables: {
            input: {
              id,
              data: {
                status: 'GRANTED',
                grantedUntil: grantedUntil.toISOString(),
              },
            },
          },
        });

        if (data) {
          this.$apollo.queries._accessRequests.refetch();
        }
      } catch (error) {
        console.error(error);
        this.$showGenericError();
      }
    },
  },

  apollo: {
    _accessRequests: {
      skip() {
        return !['ADMIN', 'SCHOOL', 'MANAGER'].includes(this.$user.role);
      },

      query: gql`
        query AccessRequests($filter: AccessRequestsFilter) {
          accessRequests(first: ${GraphQL.MAX_SAFE_INTEGER}, filter: $filter) {
            edges {
              node {
                ${ACCESS_REQUEST_FRAGMENT}
              }
            }
          }
        }
      `,

      variables() {
        return !this.schoolId || this.schoolId === 'default'
          ? undefined
          : {
              filter: {
                schoolId: { equals: this.schoolId },
              },
            };
      },

      update(data) {
        return data?.accessRequests?.edges.map(({ node }) => node) ?? [];
      },
    },

    school: {
      query: GetOrganisationById,

      skip() {
        return (
          !['ADMIN', 'SCHOOL', 'MANAGER', 'SCHOOL_DISTRICT_MANAGER'].includes(
            this.$user.role,
          ) ||
          !this.schoolId ||
          this.schoolId === 'default'
        );
      },

      variables() {
        return { id: this.schoolId };
      },

      update(data) {
        return data.getOrganisationById;
      },
    },
  },
};

function validate() {
  if (['ADMIN', 'SCHOOL', 'SCHOOL_DISTRICT'].includes(this.$context)) return;
  throw new Error(`Invalid context ${this.$context}`);
}
