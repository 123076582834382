<template>
  <div
    class="sb_bottom-fixed-buttons"
    :class="{ 'side-menu-indent': withSideMenuIndent }"
  >
    <div class="sb_bottom-fixed-buttons_container" :class="{ center }">
      <div class="sb_bottom-fixed-buttons_left">
        <slot></slot>
        <slot name="left"></slot>
      </div>
      <div class="sb_bottom-fixed-buttons_right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
export default {
  name: 'SbBottomFixedButtons',
  mixins: [
    nonReactiveMembersMixin(() => ({
      iOS: /iPhone|iPod|iPad/.test(navigator.platform),
    })),
  ],
  props: {
    withSideMenuIndent: { type: Boolean, default: true },
    center: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.sb_bottom-fixed-buttons {
  position: fixed;
  z-index: 999;
  left: 8px;
  height: rem-calc(88);
  width: calc(100vw - 16px);
  bottom: calc(8px + env(safe-area-inset-bottom));
  background: $brand-white;
  box-shadow: 0px -5px 5px rgba($brand-black, 0.1);
  display: flex;
  align-items: center;
  padding: 0 rem-calc(40);

  @media screen and (max-width: 1200px) {
    padding: 0 2rem;
  }

  @media screen and (min-width: 1200px) {
    padding: 0 rem-calc(80);
  }

  &_container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(.center) {
      @include max-content-width;
    }
    &.center {
      @include max-content-width(true);
    }
  }

  &.side-menu-indent {
    @media screen and (min-width: rem-calc(1060)) {
      padding-left: calc(rem-calc(239) + 2rem);
    }
  }
}
</style>
