// import QueryMe from '@/graphql/queries/Me.gql';
// import QueryCurrentSchoolAccessRequests from '@/graphql/queries/CurrentSchoolAccessRequests.gql';
// import { getRole } from '@/lib/user-context-manager';

import { accessManagerMixin } from './accessManagerMixin';

export default {
  mixins: [accessManagerMixin],

  computed: {
    amountOfAccessRequest() {
      if (this.$user.role === 'ADMIN') return 0;
      return this.accessRequests.filter(
        (request) => request.status === this.AccessRequestStatus.PENDING,
      ).length;
    },
  },

  methods: {
    badge(route) {
      if (route.meta && route.meta.badge) return this[route.meta.badge];
      return 0;
    },
  },
};
