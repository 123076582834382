<template>
  <div class="sb_menu-tabs" :class="'v_menu-type-' + menuType">
    <div class="sb_menu-tabs_items">
      <div
        v-for="route in filteredRoutes"
        :key="route.name"
        class="sb_menu-tabs_item"
      >
        <sb-badge
          v-show="route.meta && route.meta.badge && badge(route)"
          color="red"
          class="sb_menu-main_badge"
        >
          {{ badge(route) }}
        </sb-badge>
        <router-link
          :id="`sb_menu-tabs_link_${route.path}`"
          tag="button"
          :to="usePaths ? { path: route.path } : { name: route.name }"
          exact
          :data-path="route.path"
        >
          {{ (route.meta && route.meta.title) || 'NO-META-TITLE' }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SbBadge from '@/components/SbBadge';
import BadgeMixin from '@/mixins/badgeMixin.js';

export default {
  name: 'SbMenuTabs',
  components: {
    SbBadge,
  },
  mixins: [BadgeMixin],

  props: {
    /**
     * The type of the menu, possible values: 'default' or 'small'.
     */
    menuType: {
      default: 'default',
      validator: (value) => ['default', 'small'].includes(value),
    },
    routes: {
      type: Array,
      default() {
        return [];
      },
    },
    currentRoute: {
      type: Object,
      default() {
        return {};
      },
    },
    usePaths: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredRoutes() {
      return this.routes.filter((route) => this.shouldShow(route));
    },
  },
  mounted() {},
  methods: {
    shouldShow(route) {
      if (!route.meta) return true;
      if (route.meta.hideFromMenu) return false;
      if (route.meta.showFor) {
        return route.meta.showFor.some((condition) => {
          if (!condition.context) return condition.role === this.$user.role;
          if (!condition.role)
            return condition.context === this.$user.context.resourceType;
          return (
            condition.role === this.$user.role &&
            condition.context === this.$user.context.resourceType
          );
        });
      }
      if (route.meta.hideFor) {
        return !route.meta.hideFor.some((condition) => {
          if (!condition.context) return condition.role === this.$user.role;
          if (!condition.role)
            return condition.context === this.$user.context.resourceType;
          return (
            condition.role === this.$user.role &&
            condition.context === this.$user.context.resourceType
          );
        });
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.sb_menu-tabs {
  position: relative;
  background: $brand-lightest-gray;

  @media print {
    display: none;
  }

  &_items {
    position: relative;
    z-index: 2;
    display: flex;
  }

  //
  //  ITEMS
  //

  &_items {
    display: flex;
    padding: 0 1rem;
  }

  &_item {
    position: relative;

    > button {
      color: $brand-black;
      position: relative;
      display: flex;
      align-items: center;
      padding: 1.2rem 0;
      margin: 0 0.8rem;
      font-weight: 600;
      white-space: nowrap;
      user-select: none;
      transition: color 0.3s ease, border-bottom 0.3s ease;
      transition: color 0.2s ease;

      @media screen and (min-width: 1300px) {
        margin: 0 1rem;
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        content: '';
        background: $brand-primary;
        transition: transform 0.3s ease;
        transform: scaleY(0) translate3d(0, 0, 0);
      }

      &.s_active {
        &::after {
          transform: scaleY(1);
        }
      }

      &:hover {
        color: $brand-primary;

        &::after {
          transform: scaleY(1.01);
        }
      }
    }

    .sb_menu-main_badge {
      transform: translateX(2px) translateY(-18px);
    }
  }

  //
  //  BOTTOM LINE
  //
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 1px;
    content: '';
    background: $brand-light-gray;
  }

  //
  //  MENU TYPES
  //
  &.v_menu-type-default {
  }

  &.v_menu-type-small {
    background: $brand-white;

    .sb_menu-tabs_item {
      padding: 0rem 0;

      > button {
        font-weight: 400;
        padding: 1rem 0;
      }
    }
  }
}
</style>
